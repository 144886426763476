@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');

nav {
    padding: 1.5rem 0;
    position: static;
}
.orange_color_nav{
    background-color: #FEF6DA;
}
.white_nav{
    background-color: #fff !important;
}

.navbar__brand img {
    width: 100%;
    height: auto;
}

.navbar__brand {
    width: 90px;
}

.brand__and__toggler {
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.navbar__collapse {
    display: none;
}

.navbar__collapse .nav__link {
    letter-spacing: 1px;
    transition: var(--transition);
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
    font-family: Manrope, sans-serif;
}

.navbar__collapse .nav__link:hover,
.nav__link {
    color: var(--robin-blue-color);
}

/* smallscreen */
.navbar__smallscreen {
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--robin-blue-color);
    height: 100%;
    width: 100%;
    padding: 3rem;
    box-shadow: -3px 0 14px 0 rgba(0, 0, 0, 0.4);
    z-index: 999;
    transition: var(--transition);
}
.text__light{
    color: #fff;
}
.sticky {
    position: sticky;
    /* position: fixed; */
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(3, 4, 28, 0.1);
    top: 0px;
    z-index: 999;
    transition: all 0.3s ease;
    animation: fadeInDown 0.5s ease-in-out 0s 1 normal none running;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.navbar__close--btn {
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
    transition: var(--transition);
}

.navbar__close--btn:hover {
    opacity: 0.9;
}

.navbar__smallscreen .nav__link {
    transition: var(--transition);
    letter-spacing: 1px;
    font-size: 1.8rem;
}

.navbar__smallscreen .nav__link:hover {
    padding-left: 1rem;
}

.navbar__smallscreen .nav__item {
    margin: 2rem 0;
}

.navbar__nav .nav__item a {
    transition: var(--transition);
    position: relative;
    white-space: nowrap;
}

.navbar__nav .nav__item a:hover {
    color: #007aff;
}

.navbar__nav .nav__item a:before {
    content: "";
    -webkit-margin-end: 12px;
    margin-inline-end: 12px;
    display: inline-block;
    transition: all 0.3s ease-out 0s;
    width: 6px;
    height: 6px;
    top: 50.76px;
    background: #007aff;
    position: absolute;
    top: 50%;
    inset-inline-start: -12px;
    transform: translateY(-50%) rotate(45deg);
    opacity: 0;
}

.navbar__nav .nav__item:hover>a:before {
    opacity: 1;
}

.buttonNavbarRight {
    display: none;
    align-items: center;
    margin-left: 100px;
}

.buttonNavbarRightAnchor {
    cursor: pointer;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    background-color: #007aff;
    line-height: 1;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    color: #fff;
    border-radius: 30px;
    z-index: 55;
    width: max-content;
    transition: var(--transition);
}

.buttonNavbarRightAnchor:hover {
    -ms-box-shadow: 0 24px 40px 0 hsla(0, 3%, 6%, 0.3);
    -o-box-shadow: 0 24px 40px 0 hsla(0, 3%, 6%, 0.3);
    box-shadow: 0 24px 40px 0 hsla(0, 3%, 6%, 0.3);
}

@media (min-width: 578px) {
    .navbar__smallscreen {
        width: 300px;
    }
}

@media (min-width: 992px) {
    .navbar__open--btn {
        display: none;
    }

    .navbar__collapse {
        display: flex;
        width: 100%;
    }

    .buttonNavbarRight {
        display: flex;
    }

    .navbar__collapse .navbar__nav {
        display: flex;
        align-items: center;
    }

    .navbar__collapse .nav__item {
        margin-left: 3.8rem;
    }

    .navbar__smallscreen {
        display: none;
    }
}

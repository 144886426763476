@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Martian+Mono:wght@700&display=swap');
:root{
  --font-base: 'Manrope', sans-serif;
  --robin-blue-color: #222;
  --body-color:#FFFAEB;
  --black-color: #141414;
  --white-color: #fff;
  --transition: all 300ms ease-in-out;
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
html{
  font-size: 10px;
}
body{
  font-size: 1.8rem;
  line-height: 1.6;
  font-family: var(--font-base);
  color: var(--black-color);
  background: var(--body-color);
}

a{
  color: unset;
  text-decoration: none;
}
ul{
  list-style-type: none;
}
img{
  width: 100%;
  display: block;
}
button{
  cursor: pointer;
  outline: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 1.8rem;
}
input, textarea{
  outline: 0;
  border: none;
  resize: none;
}

.container{
  max-width: 1177px;
  margin: 0 auto;
  padding: 0 2rem;
}

.flex{
  display: flex;
}
.flex__center{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* btns */
.btn{
  display: inline-block;
  min-height: 54px;
  line-height: 54px;
  padding: 0 4rem;
  color: var(--white-color);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.7rem;
  font-weight: 500;
  border-radius: 40px;
  transition: var(--transition);
}
.btn__blue{
  background-color: #6610f2;
}
.btn__blue:hover{
    background-color: #222;
    scale: 1.1;
}
.rotate-on-hover {
  display: inline-block;
  transition: transform 0.3s ease;
}

.rotate-on-hover:hover .icon {
  transform: rotate(70deg);
}

.icon {
  display: inline-block;
  transition: transform 0.3s ease;
}



@media screen and (max-width: 768px) {
  .btn{
    width: 100%;
    text-align: center;
  }
}











.active-deals-container{
    height: fit-content;
    background-color: #fff;
  }
.active-deal-header{
    width: 100%; 
    flex-direction: column;
    height: 40vh; 
    background: linear-gradient(to bottom, #fff, rgb(241,241,252));
  }
.active-deal-title{
  display: flex;
  text-align: center;
  flex-direction: column;
}
  /* search bar css starts  */
  .active-deal-search-bar {
   margin-top: 10px;
   position: relative;
  }
  .active-deal-search-bar.fixed {
    position: fixed;
    top: 78px;
    width: 100%;
    z-index: 1000;
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  
  }
  .input-box-section {
    display: flex;
    align-items: center;
    width: 300px; 
    border: 1px solid #ccc; 
    border-radius: 15px; 
  }
  
  .active-deal-search-box {
    flex: 1;
    padding: 5px;
    margin-right: 10px;
    padding-left: 28px;
  }
  .active-deal-search-icon {
    margin-left: 12px;
    color: #666; 
  }
  
  .filter-icon {
    margin-right: 20px;
    cursor: pointer;
    font-size: 25px;
    color: #666;
    padding-top: 5px;
  }
  /* .active-deal-scroll-container{
    margin: 10%;
    overflow-y: scroll;
  } */
  .filter-dropdown {
    background: #fff; 
    border: 1px solid #eaeff5; 
    display: block;
    position: absolute;
    top: 101% !important;
    cursor: pointer;
    z-index: 1000;
    left: 42%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .filter-dropdown.fixed {
       top: 154px !important;
       left: 42%;
       z-index: 1001;
       position: fixed;
  }

  .filter-dropdown ul li{
    display: block;
    list-style-type: none;
    padding: 10px 25px;
    background: #fff;
    border-top: 1px solid #e6e6e6;
    font-size: 14px;
    line-height: 1.4;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    font-weight: 500;
    border-left: 3px solid transparent;
    font-size: 14px;
    text-align: left;
    color: #1c4980;
    font-weight: 500;
    width: 250px;
   
  }

  .filter-dropdown ul li:hover{
    background: rgba(0,115,230,.03);
    color: #0073e6;
    border-left-color: #0073e6;
  }
  /* search bar css ends  */
.noDataFound{
  font-size: 30px;
  display: flex;
  justify-content: center;
}
  /* Styles for mobile devices */
@media (max-width: 767px) {
  .active-deal-title{
    font-size: 20px;
  }
}

  /* Styles for small screens with low height (e.g., mobile phones in portrait mode) */
  @media (max-height: 700px) {
    .filter-dropdown{
      top: 63%;
    }
  }
  
  /* Styles for screens with high height (e.g., large desktop monitors) */
  @media (min-height: 901px) {
    .filter-dropdown{
      top: 53%;
    }
  }

  @media (max-width: 420px) {
    .filter-dropdown{
      left: 18%;
    }
    .filter-dropdown.fixed{
      left: 18%;
    }
  }

  @media (min-width: 500px) and (max-width: 1000px) {
    .filter-dropdown{
      left: 34%;
    }
    .filter-dropdown.fixed{
      left: 34%;
    }
  }
.active-deal-card-container{
    width: 100%;
}

.deal-card{
    width: 70%;
    border: 1px solid #eaeff5;
    display: flex;
    flex-direction: column;
    margin: 10px 15%;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: transform 0.2s; 
}
.deal-card:hover{
    border: 1px solid #6610f2;
    transform: scale(1.01);
}

.deal-title{
    margin-bottom: 15px;
}
/* 1st  row css  starts */
.topbar-detail-box{
    display: flex;
    background-color: rgb(244, 244, 248);
    justify-content: flex-start;
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    gap: 20px;
}

.active-deal-company-logo{
    height: 55px;
    width: 100px;
}
.active-deal-company-logo img{
    max-width: 100%;
    height: auto;
}
/* 1st  row css  ends */



/* 2nd row css starts */
.top-detail-box{
    display: flex;
    justify-content:space-between;
    padding: 20px;
    gap: 10px;
}
.top-detail-box p{
    font-size: 20px;
    color: #666;
    /* word-break: break-all; */
}
.top-detail-box p span{
    color: #222;
}

.deal-buttons{
    padding: 10px 0px;
    gap: 15px;
    display: flex;
    flex-direction: column;
}
.deal-read-more,
.deal-apply-now{
    background: #7e37f0;
    color: #fff;
    font-weight: bold;
    padding: 12px;
    font-size: 15px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
    white-space: nowrap;
}
.active-deal-location{
    word-break: break-all;
}
.deal-read-more:hover,
.deal-apply-now:hover{
    background-color: #fff;
    color: #6610f2;

}
/* 2nd row css ends */


/* 3rd row css starts */
.bottom-detail-box{
    display: flex;
    justify-content:space-between;
    background:  rgb(241,241,252);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px;
}
.deals-skill{
    display: flex;
    font-size: 20px;
}
.deals-skill p:first-child{
    margin-top: 5px;
    margin-right: 10px;
}
.active-deal-tag{
    background: #6610f2;
    border-radius: 19px;
    width: fit-content;
    color: white;
    padding: 4px 10px 4px 10px;
    font-size: 14px;
    margin-top: 8px;
    margin-right: 6px;
    text-transform: capitalize;
    display: inline-block;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis; 
    word-break: break-all;
}
/* 3rd row css ends */



/* Styles for mobile devices */
  @media (max-width: 900px) {
    .deal-card{
        width: 90%;
        margin: 15px 5%;
    }
    .topbar-detail-box{
        flex-direction: column;
        padding: 28px;
    }
    .active-deal-company-logo img{
        width: 100%;
    }
    .active-deal-company-logo img{
        height: 60px;
    }
    .top-detail-box  span{
        white-space: nowrap;
    }
    .deal-title{
        margin-bottom: 0;
    }
    .detail-box{
        display: flex;
    }
    .detail-box p{
        margin-left: 30%;
        word-break: break-all;
       
    }

    
    .job-type{
        position: relative;
        right: 26px;
    }
    .active-deal-location{
        position: relative;
        right: 22px;
    }
    .active-deal-exp{
        position: relative;
        right: 44px;
    }
    .top-detail-box{
        flex-direction: column;
    }
    .deals-skill{
        margin-bottom: 10px;
    }
    .deal-buttons{
        flex-direction: column;
        width: 43%;
        margin-left: 25%;
    }
    .bottom-detail-box{
        flex-direction: column;
    }
    .deals-skill{
        flex-direction: column;
    }
    .tag-continer{
       display: flex;
       flex-wrap: wrap;
    }
   
  }
  @media (max-width: 406px) {
    .job-type{
        right: 10%;
    }
    .deal-title{
        margin-bottom: 0;
    }
    .job-id{
        position: relative;
        left: -4px;
    }
  }




  

.pagination{
    position: relative;
    bottom: 102px;
    left: 37%;
    width: fit-content;
}

.pagination-button {
    background-color: #5355DF;
    color: #fff !important;
    width: 40px; 
    height: 40px; 
    border-radius: 50%;
    margin: 5px; 
    cursor: pointer;
  }
.active-button{
    background-color: #222;
}
  
@media screen and (max-width: 920px) {
    .pagination{
        left: 65px;
    }
}
/* .howitWorkMainContainer{
  background-image: url('https://webtend.site/html/jeena/assets/images/shapes/project-right.png');
  background-repeat: no-repeat;
} */
.HowItWorkcard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #F1F1FC;
  width: 40rem;
  height: fit-content;
  padding: 0 2rem 2rem 2rem;
  border-radius: 10px;
}
.howItwork_container{
  font-family: sans-serif;
  height: 90vh;
}
.howItworksHeading{
  text-align: center;
  margin-bottom: 30px;
}
.howItworkType {
  color: #999;
}

.HowItWorkcard img {
  margin-top: -20%;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.45), -25px 0 20px -20px rgba(0, 0, 0, 0.45);
}

.HowItWorkcard h2 {
  margin: 0;
  margin-top: 1rem;
}

.HowItWorkcard p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}



@media (max-width: 768px) {

  .HowItWorkcard{

    width: 27rem;

  }

}

/* Flex container for the 'hire-client' section, organized as a column */
.hire-client {
  flex-direction: column;
}

/* Flex container for the 'hiretalent' section */
.hiretalent {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 0 30px;
  gap: 70px;
  min-height: 100vh;
  position: relative;
}

/* Image column for 'img-col-2' */
.img-col-2 {
  object-fit: contain;
  flex-shrink: 0;
  width: 100%;
  margin-top: 15%;
}

/* Styles for the first text row */
.text-row-1 {
  display: flex;
  font-size: 40px;
  font-weight: bolder;
  line-height: 2.36;
}

.inverted-logo {
  filter: invert(1);
}


/* Keyframes animation for border */
@keyframes borderAnimation {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Styles for the second text row */
.text-row-2 {
  line-height: 1.52;
  font-size: 22px;
}

/* Text column for 'text-col' */
.text-col {
  margin-top: 17%;
}

/* Media query for screens with a max width of 1150px */
@media (max-width: 1150px) {
  /* Adjust the layout for 'hiretalent' section when the screen is narrower */
  .hiretalent {
    flex-direction: column;
  }

  /* Modify the line height for 'text-row-1' for better responsiveness */
  .text-row-1 {
    line-height: 1;
  }
}

/* Media query for screens with a min width of 1150px */
@media screen and (min-width: 1150px) {
  /* Adjust the width of 'img-col-2' when the screen is wider */
  .img-col-2 {
    width: 40%;
  }
}

/* Media query for screens with a min width of 992px */
@media (min-width: 992px) {
  /* Display the 'hire-client' section as a column when the screen is wider */
  .hire-client {
    display: flex;
    flex-direction: column;
  }
}

/* Media query for screens with a min width of 1012px and a max width of 1114px */
@media (min-width: 1012px) and (max-width: 1114px) {
  /* Adjust the top margin of 'text-col' for responsive layout */
  .text-col {
    margin-top: 27%;
  }
}

/* Media query for screens with a min width of 1114px and a max width of 1335px */
@media (min-width: 1114px) and (max-width: 1335px) {
  /* Adjust the top margin of 'text-col' for responsive layout */
  .text-col {
    margin-top: 18%;
  }
}

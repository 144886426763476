.callback-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    overflow: auto;
  }
  
  .modal-content {
    background-color: #f9f9f9;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 30%;
    text-align: center;
    border-radius: 20px;
  }
  
  .modal-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    top: -20px;
  }
  
  .modal-close:hover {
    color: black;
  }
  
  .callback-modal.active {
    display: block;
  }
  

  .input-container {
    position: relative;
    margin: 20px 0;
  }
  
  .input-container input {
    width: 100%;
    padding: 15px;
    box-shadow: unset;
    border: 2px solid #DEE2E9;
    font-size: 16px;
    border-radius: 10px;
    transition: border-bottom 0.3s;
  }
  
  .input-container label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    font-size: 16px;
    transition: top 0.3s, font-size 0.3s;
    color: #888;
    margin-left: 10px;
    margin-top: 10px;
  }
  
  .input-container input:focus {
    border-bottom: 2px solid #007BFF;
  }
  
  .input-container input:focus + label,
  .input-container input:valid + label {
    top: -30px;
    font-size: 14px;
    color: #007BFF;
  }
  .callbackLabel{
    color: #888;
    margin: 10px;
  }
  #email-container {
    margin-bottom: 20px;
  }
  
  #phone-container {
    margin-bottom: 40px;
  }
.callBackButton{
    width: 50%;
    background-color: #007BFF;
    padding: 2%;
    color: #fff;
    border-radius: 20px;
}
.callBackButton:hover{
    background-color: #DEE2E9;
    color: #007BFF;
}

.error-message{
    color: red;
    font-size: 12px;
}

  /* Styles for mobile devices */
  @media (max-width: 767px) {
    .modal-content{
      width: 90%;
      margin: 50% auto;
    }
    .input-container input {
      width: 90%;
    }
    .input-container label{
      font-size: 12px;
      margin-left: 30px;
      margin-top: 10px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .modal-content{
      width: 50%;
    }
  }
  
/* Common css for both case-study as the UI is same  */
.readmore-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-bottom: 10rem;
  }
  
  .readmore-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 50vh;
    background: radial-gradient(50% 50% at 50% 50%, #8d6bd2 0%, rgba(116, 74, 204, 0.90) 100%);
  }
  
  .header-col1 {
    margin-top: 20px;
    gap: 20px;
    min-width: 50%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  
  .header-col1 h2 {
    margin: auto;
    color: white;
    max-width: 70%;
    word-wrap: break-word;
  }
  
  .header-col1 p {
    margin: auto;
    margin-top: 15px;
    color: white;
    max-width: 70%;
    word-wrap: break-word;
  }
  
  .header-col2 {
    min-width: 30%;
    border-bottom-left-radius: 255px;
    border-top-left-radius: 255px;
    background: linear-gradient(200deg, rgba(120, 80, 205, 0.00) 0%, #9362fc 100%);
  }
  
  #readme-img {
    object-fit: cover;
    width: 8%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .info-img img {
    max-width: 64%;
    border-radius: 20px;
    margin-bottom: 3rem;
  
  }
  
  .info-section {
    display: flex;
    margin-left: 26rem;
    margin-right: auto;
    margin-top: 5rem;
    width: 74%;
  }
  
  .info-card {
    position: relative;
    left: -5rem;
    top: 0rem;
    max-width: none;
    background-color: white;
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  
  .infocard-col1 {
    padding: 20px 0;
  }
  
  .infocard-col1 p {
    font-size: 20px;
  }
  
  .infocard-col2 p {
    font-size: 18px;
    font-weight: 600;
    color: #7850CD;
  }
  
  .card-textcontainer {
    display: flex;
    flex-direction: row;
    flex: 0.5;
  }
  
  .content-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 44px;
    margin-top: 5rem;
  }
  
  .content-about {
    display: flex;
    flex: 0.75;
    gap: 1rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    justify-content: center;
    flex-direction: column;
  }
  
  .content-about p {
    font-size: 20px;
    line-height: 38px;
  }
  
  .content-about h2 {
    font-size: 46px;
  }
  
  .casestudy-card {
    max-width: none;
    width: 31%;
    margin-right: 18px;
    border-radius: 10px;
    padding-top: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background: wheat;
  }
  
  .casestudy-card .casestudy-header {
    background: wheat;
    padding: 20px 40px;
  
  }
  
  .casestudy-list {
    background-color: #9168e7;
    list-style: none;
    padding: 30px 40px;
  }
  
  .casestudy-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    font-size: large;
  }
  
  .casestudy-list li span {
    color: white;
    font-size: 25px;
    font-weight: 500;
    transition: margin-left 0.3s ease;
  }
  
  .casestudy-list li span:hover {
    margin-left: 10px;
    padding-left: 15px;
  }
  
  
  .content-challenge {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 60%;
    margin-left: 26rem;
  }
  
  .challenge-text p {
    max-width: 100%;
    margin-bottom: 10px;
  }
  
  .card-container {
    position: relative;
    width: 50%;
    left: 25rem;
    top: 10rem;
    background-color: antiquewhite;
    padding: 20px 30px;
  }
  
  .card-container img {
    width: 29%;
    margin: auto;
    border-radius: 50%;
  }
  
  .card-title {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .card-container ul {
    margin-top: 40px;
  }
  
  .about-list {
    display: flex;
    gap: 20px;
  }
  
  .about-list1 {
    display: flex;
    gap: 70px;
  }
  
  .about-list3 {
    display: flex;
    gap: 34px;
  }
  
  .challenge-text {
    display: flex;
    width: 85%;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 14px;
  }
  
  .challenge-text p {
    width: 100%;
  }
  
  .challenge-text h2 {
    font-size: 2.188rem;
    font-weight: 700;
    color: black;
  }
  
  .process-text h2 {
    font-size: 2.188rem;
    font-weight: 700;
    color: black;
  }
  
  .challenge-img {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: flex-start;
  }
  
  .image1 {
    max-width: 70%;
  }
  
  .image2 {
    max-width: 70%;
  }
  
  .image1 img,
  .image2 img {
    width: 32rem;
    height: 17rem;
    border-radius: 30px;
  }
  
  .process-text {
    margin-top: 2rem;
  }
  
  .challenge-card {
    display: flex;
    flex-direction: row;
  }
  
  .process-text p {
    margin-bottom: 1rem;
  }
  
  .list-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
  
  .list-title h3 {
    font-size: 1.875rem;
    font-weight: 700;
    color: #292b31;
  }
  
  .list-title img {
    height: 36px;
    width: 34px;
  }
  
  .process-list p {
    padding-left: 60px;
    max-width: 100%;
  }
  
  .result-text {
    margin-top: 20px
  }
  
  .content-challenge p {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 1.8;
    max-width: 600px;
    text-align: justify;
  }
  
  .customerwords-card {
    display: flex;
    gap: 5rem;
    flex-direction: row;
    margin-top: 7rem;
    background: antiquewhite;
    padding: 20px 10px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(321deg, rgba(214, 214, 236, 1) 18%, rgba(223, 234, 246, 1) 32%, rgba(229, 247, 253, 1) 70%, rgba(237, 219, 246, 1) 83%, rgba(214, 214, 236, 1)100%);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
  }
  
  .CTA-button {
    background: #7850CD;
    color: white;
    padding: 10px;
    border-radius: 24px;
  }
  
  .CTA-card,
  .CTA-resposive {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    top: 22rem;
    padding: 20px 20px;
    left: 40rem;
    background-color: #f5f8fa;
  }
  
  .customer-img {
    position: relative;
    width: 25rem;
    height: 25rem;
  }
  
  
  .customer-img::before {
    content: '';
    position: absolute;
    top: -12px;
    left: -12px;
    width: 47px;
    height: 34px;
    background: url(https://www.jobtwine.com/assets/img/success-stories/Vector.svg) no-repeat;
    background-size: cover;
  }
  
  .customer-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
  
  }
  
  .customer-text p {
    font-size: 18px;
  }
  
  
  @media(max-width:1200px) {
    .challenge-img {
      flex-direction: column;
    }
  
    .content-about h2 {
      font-size: 32px;
    }
  
  
    .content-section {
      margin-left: 23px;
    }
  
  
    .content-section {
      flex-direction: column;
      gap: 20px
    }
  
    .casestudy-card {
      width: 100%;
    }
  
    .card-textcontainer {
      flex-direction: column;
    }
  
    .card-container {
      position: relative;
      width: 100%;
      left: auto;
      top: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  
  }
  
  @media (max-width: 768px) {
  
    .about-list,
    .about-list1,
    .about-list3 {
      display: block;
    }
  
    .about-list,
    .about-list1,
    .about-list3 h4 {
      margin-bottom: 10px;
    }
  
    .about-list1 {
      gap: 20px;
    }
  
    .about-list3 {
      gap: 10px;
    }
  
    .info-card {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      top: 0;
      width: 100%;
      box-shadow: none;
    }
  
    .info-section {
      width: 100%;
      margin-left: 0;
      flex-direction: column;
    }
  
    .info-img img {
      max-width: 100%;
    }
  
    .content-challenge {
      width: 85%;
      margin-left: 4rem;
    }
  
    .customerwords-card {
      flex-direction: column;
    }
  
    .CTA-card {
      display: none;
    }
  
    .CTA-resposive {
      position: relative;
      top: 5rem;
      left: 0;
      width: 100%;
    }
  
    .process-text {
      width: 93%;
    }
    .header-col2{
      display: none;
    }
  }
  
  @media(min-width:800px) and (max-width:1450px) {
    .CTA-card {
      display: none;
    }
    .CTA-resposive {
  
    left: 0;
      top: 1rem;
  }
  }
  @media(min-width:1201px) and (max-width:1300px) {
    .CTA-card {
      display: none;
    }
    .CTA-resposive {
  
    left: 0;
      top:5rem;
  }
  }
  @media (min-width: 769px) and (max-width:1500px) {
    .info-card {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      box-shadow: none;
      /* max-width: 100%; */
      margin-bottom: 20px;
  
    }
  
    .info-img {
      width: 100%;
    }
  
    .info-cardimg {
      width: 100%;
  
    }
  
    .infocard-col1 {
      padding: 20px 0;
    }
  
    .infocard-col1 p {
      font-size: 25px;
    }
  
    .infocard-col2 p {
      font-size: 24px;
      font-weight: 600;
      color: #7850CD;
  
    }
  
    .card-textcontainer {
      flex-direction: column;
    }
  
    .card-container {
      position: relative;
      width: 100%;
      left: auto;
      top: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  
    .customerwords-card {
      flex-direction: column;
    }
    .info-section{
      flex-direction: column;
    }
    .CTA-card{
      display: none;
    }
    .CTA-resposive{
      top:6rem;
      left:0
    }
  }
  
  @media (min-width:1500px) and (max-width:2000px) {
    .CTA-card {
      top: 30rem;
      left: 30rem;
    }
    .CTA-resposive{
      display: none;
    }
  }
  
  @media (max-width:350px) {
  
    .image1 img,
    .image2 img {
      width: 17rem;
      height: 12rem;
    }
  
    .customerwords-card img {
      width: 21rem;
      height: 21rem;
      margin-left: 16px;
    }
  
    .content-challenge {
      margin-left: 3rem;
    }
  
    .customerwords-card {
      gap: 0
    }
  }
  
  @media(min-width:800px) and (max-width:820px) {
    .info-section {
      margin-left: 17rem;
    }
  
    .content-challenge {
      margin-left: 18rem;
    }
  
    .customerwords-card {
      gap: 5rem;
    }
  
    .customer-img {
      margin-top: 2rem;
    }
  }
  @media (min-width:1500px){
    .info-section{
      left:6rem
    }
  }
.blog-container{
  height: fit-content;
  background-color: #fff;
}
/* blog header starts  */
.blog-header{
  width: 100%; 
  flex-direction: column;
  height: 40vh; 
  background: linear-gradient(to bottom, #fff, rgb(241,241,252));

}
/* .blog-header::before{
inset-inline-start: 0;
inset-inline-end: 0;
bottom: 45%;
z-index: 1;
content: "";
height: 32px;
position: absolute;
background-size: cover;
background-repeat: no-repeat;
background-position: bottom;
background-image: url(https://paradox-react.vercel.app/_next/static/media/shape_up_white.53bc00d1.svg);
} */

.blog-title{
  text-align: center;
  position: relative;
  font-size: 33px;
}
/* blog header ends  */


.bolg-main-section{
  display: flex;
}


/* blog page left side section starts*/
.blog-left-sidebar{
  width: 30%;
  margin: 3%;
}
.from-the-blog-section ,
.popular-posts{
  background-color: rgb(241,241,252);
  padding: 8%;
  border-radius: 25px;
  margin-bottom: 5%;
}
.from-the-blog-section h3,
.popular-posts h3{
  margin-bottom: 4%;
}
.from-the-blog-section p{
  font-size: 17px;
  color: #666;
}
.popular-post-item{
  display: flex;
  margin-bottom: 5%;
}

.popular-post-item img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 20px;
  margin-right: 20px;
  cursor: pointer;
}

/* starts--image-wrapper section to scale the image on hover from inside */
.img-wrapper{
  overflow: hidden;
  border-radius: 20px;
}
.img-wrapper img{
  transition: transform .5s ease;
}
.img-wrapper:hover img{
  transform: scale(1.1);
}
/*end--image-wrapper section to scale the image on hover from inside */



/* popular post section starts */
.popular-post-content {
  width: 80%; 
  margin: 10px 0;
  margin-left: 15px;
}

.blog-date {
  font-weight: 500;
  margin-bottom: 5px;
  color: #666; 
  font-size: 12px;
}

.popular-post-content p:last-child {
  font-weight: bold;
  font-size: 15px;
  margin: 0;
  cursor: pointer;
}
.popular-post-content p:last-child:hover{
  color: rgb(76, 76, 229);
}
/* popular post section starts */
/* blog page left side section ends*/



/* right section css starts*/
.blog-right-section{
  width: 60%;
  margin-top: 3%;
}
.post-items-container {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between; 
}
.post-item {
  width: calc(50% - 10px); 
  margin-bottom: 20px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: rgb(241,241,252);
  padding: 3%;
  border-radius: 20px;
}
.post-item h3{
  margin-top: 8%;
  font-size: 19px;
}
.post-item p{
  color: #666;
  display: flex;
  align-items: center;
  margin-top: 2%;
  font-size: 15px;
}

.post-item:hover{
  color: rgb(76, 76, 229);
}
.post-item p:hover{
  color: rgb(76, 76, 229);
}
.post-item:not(:nth-child(2n)) {
  margin-right: 20px;
}
.post-item:nth-child(2n) {
  margin-right: 0;
}


/* posted by style starts */
.posted-by-container{
  display: flex;
  justify-content:space-between;
}
.posted-by{
  background-color: rgb(76, 76, 229);
  color: #fff !important;
  border-radius: 30px;
  padding: 1.8%;
  font-size: 13px !important;
  font-weight: 600;
}
/* posted by style ends */

.blog-img img{
  height: 270px;
  width: 100%;
  object-fit: cover;
}
/* right section css ends*/

@media screen and (max-width: 920px) {
  .bolg-main-section{
      flex-direction: column-reverse;
  }
  .blog-left-sidebar{
      width: 90%;
  }
  .blog-right-section{
      width: 100%;
  }
  .post-items-container{
      margin-left: 3%;
      margin-right: 3%;
  }
  .from-the-blog-section ,
  .popular-posts{
   width: 100%;
   margin-left: 3%;
}
}
@media screen and (max-width: 768px) {
  .post-item{
      width: calc(100% - 10px); 
  }
}
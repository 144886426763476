.loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.loading-container img{
    height: 200px;
    width: 400px;
}

.loading-logo {
    opacity: 0;
    transform: scale(0);
    animation: comeInOut 3s infinite;
  }
  
  @keyframes comeInOut {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: scale(1.1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }

  
/* .loading-logo {
    animation: spin 2s linear infinite;
    max-width: 100px; 
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */
.infosecuritytext{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    padding: 15px 26px;
    flex: 1 1;
    border-radius: 12px;
    background: #f1f1fc;
}
/* Testimonials Section */
.testimonials {
    min-height: 100vh;
    background-image: linear-gradient(40.25deg, rgba(253, 210, 78, 0.15), rgba(253, 210, 78, 0.1) 63.9%);
    
}
.testimonials__content {
    min-height: calc(100vh - 106px);
    flex-direction: column;
}

.testimonials__title {
    font-size: 4.1rem;
    line-height: 1.2;
    color: #222;
}

.testimonials__content .para__text {
    padding: 3.6rem 0;
    max-width: 600px;
    margin: 0 auto;
    color: #222;
    font-size: 22px;
}

/* Testimonials Profile */
.testimonials_profile {
    display: flex;
    background-color: #fff;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 20px;
    border-radius: 30px;
}

.profile-image {
    height: 50px;
    width: 50px;
    position: relative;
    top: 10px;
    border-radius: 50%;
    background-color: #222;
}

.profile-content {
    color: #999;
    margin-left: 20px;
}

/* Media Queries */
@media (min-width: 992px) {
    .testimonials__content {
        flex-direction: row;
    }

    .testimonials__content--right {
        text-align: left;
        width: 50%;
        left: 27%;
        position: relative;
    }

    .testimonials__content--left {
        width: 50%;
        left: 0px;
        position: absolute;
    }

    .testimonials__content--left img {
        width: 90%;
        height: 100vh;
        object-fit: cover;
        margin-top: 14%;
    }

    .testimonials__content--right .para__text {
        margin-left: 0;
    }

    .get_started {
        margin-left: 20px;
    }
}

/*  Media Query */
@media screen and (min-width: 1530px) and (max-width: 3000px) {
    .testimonials__content--left img {
        margin-top: 11rem;
    }
}

.header{
    min-height: 100vh;
    background-image: linear-gradient(40.25deg,rgba(253,210,78,.15),rgba(253,210,78,0.1) 63.9%);
    /* background: linear-gradient(rgb(254,250,236,0.9), rgb(254,249,235,0.3)); */
}
.header__content{
    min-height: calc(100vh - 106px);
    flex-direction: column-reverse;

}
.header__title {
  font-size: 29px;
  line-height: 1.2;
  color: #222;
  position: relative;
}

.header__title::before {
  content: "";
  position: absolute;
  bottom: -2px; 
  left: 0;
  right: 0;
  height: 18px; 
  background-color: #FFC961;
  z-index: -1;
  animation: borderAnimation 2s infinite alternate;
}

@keyframes borderAnimation {
  0% {
      opacity: 0;
      transform: translateY(-10px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}


.header__content .para__text{
    padding: 3.6rem 0;
    max-width: 600px;
    margin: 0 auto;
    color: #222;
    font-size: 19px;

}

/* css for get started button */
.get_started{
    margin-top: 20px;
    transition: transform 0.3s ease;
}
  .svg-background {
    position: absolute;
    left: -138px;
    width: 46%;
    height: 78%;
    background-image: url(https://paradox-react.vercel.app/_next/static/media/shape_banner_2.176188b4.svg);
    background-size: cover;
    z-index: 1;
    transform: rotate(93deg);
}
  
  .transparent-photo {
    margin-top: 80px;
    position: relative;
    z-index: 2;
    animation: moveUpDown 1s infinite alternate;
  }

  @keyframes moveUpDown {
    0% {
      transform: translateY(0); 
    }
    100% {
      transform: translateY(-10px); 
    }
  }


/* media query for tablet view */
@media(min-width: 992px){
    .header__content{
        flex-direction: row;
    }
    .header__content--right{
        text-align: left;
        width: 60%;
    }
    .header__content--left{
        width: 40%;
        margin-right: 30%;
    }
    .header__content--right .para__text{
        margin-left: 0;
    }
    .get_started{
        margin-left: 20px;
    }
    .header__title{
      font-size: 4.1rem;

    }

  .svg-background {
    position: absolute;
    top: 150px;
    left: -250px;
    width: 60%;
    height: 90%;
    background-image: url('https://paradox-react.vercel.app/_next/static/media/shape_banner_2.176188b4.svg');
    background-size: cover;
    z-index: 1; 
    transform: rotate(0deg);

  }

  .transparent-photo {
    position: absolute;
    top: 80px;
    left: 0;
    width: 55%;
    height: 80%;
    object-fit: contain;
    z-index: 2; 
   
  }
  .header__content .para__text{
    font-size: 22px;}
}




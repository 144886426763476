/* Main Container */
.main-div {
  display: flex;
  flex-direction: row;
  background: #FFF6E8;
  position: relative;
}

/* Subscribe Section */
.subscribe-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #FFEED4;
  color: #353738;
  min-height: 50vh;
  position: relative;
  width: 100%;
  max-width: 1215px;
  z-index: 1;
}

/* Second Div */
.second-div {
  background: #FFF6E8;
  flex-grow: 1;
  position: relative;
}

/* Full Div */
.full-div {
  background: #BED6E4;
}

/* Explore Text */
.explore-text {
  margin: 39px;
  font-size: 30px;
  margin-left: 115px;
}

/* Subscribe Inputfield Container */
.subscribe-inputfield-container {
  position: relative;
  margin: 5px;
  margin-left: 90rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* Subscribe Image */
.sub-img {
  position: absolute;
  bottom: -10px;
  right: 0;
  width: 24%;
  height: auto;
}

/* Subscribe Inputfield */
.subscribe-inputfield {
  flex: 1;
  height: 8rem;
  width: 72rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 5rem;
  border-radius: 51.5px;
  background: #FFF;
  padding-left: 2rem;
  font-size: 18px;
  box-shadow: 0px 4px 120px 0px rgba(142, 131, 113, 0.15);
  position: relative;
  z-index: 2;
}

/* Subscribe Inputfield Placeholder */
.subscribe-inputfield::placeholder {
  content: "\2713";
  color: #57656C;
  padding-left: 20px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Explore Paragraph */
#explore-p {
  color: #555;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.18px;
}

/* Inputfield Button */
.inputfield-btn {
  height: 5rem;
  width: 18rem;
  border-radius: 35.5px;
  background: #AF6F35;
  color: #ffff;
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  z-index: 3;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .subscribe-inputfield {
    width: 100%;
    max-width: none;
    padding-right: 2rem;
    margin-left: 0;
    margin-right: 0;
    height: 9rem;
    margin-bottom: 4rem;
  }

  .inputfield-btn {
    width: 100%;
    max-width: none;
    position: static;
    margin-top: 1rem;
  }

  .explore-text {
    font-size: 26px;
    margin-left: 39px;
  }

  #explore-p {
    font-size: 18px;
  }

  .subscribe-inputfield-container {
    margin-left: 0;
  }
}

@media screen and (max-width: 1450px) {
  .subscribe-inputfield-container {
    margin-left: 0;
  }
}

.privacydata{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    padding: 15px 26px;
    flex: 1 1;
    border-radius: 12px;
    background: #f1f1fc;
}
.termscard ul li{
    padding: 10px;
}
.privacydata h3{
    font-size: 18px;
   
}

.privacydata li{
    list-style: disc;
}
.privacydata ul{
    padding-left: 30px ;
}
.privacydata p{
    font-size: 12px;
    color: #5d636a;;
    padding-bottom: 7px;
    padding-top: 5px;
}
.infoShareList li{
    font-size: 12px;
    list-style: decimal;
}
.cardntext{
    display: flex;
    padding: 30px 20px;
    gap: 10rem;
}

@media(max-width:960px){
    .cardntext{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.case-study-container {
   height: fit-content;
   background-color: #fff;
  }
  
  .case-study-header {
    width: 100%; 
    flex-direction: column;
    height: 40vh; 
    background-color: rgb(243,235,255);   
    background:
    url(https://resilienteducator.com/wp-content/uploads/2012/11/how-to-write-a-case-study-the-basics.jpg)
    center / cover no-repeat fixed;
    background:
    url(https://www.centreforcities.org/wp-content/uploads/2017/09/AdobeStock_205743578-e1542059099707-1630x630.jpeg)
    center / cover no-repeat fixed;
    position: relative;
  
  }
  .case-study-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40vh;
    background-color: rgba(226, 209, 252, 0.3);
}
  .case-study-title {
    text-align: center;
    position: relative;
  }
 

 



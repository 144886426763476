.job-detail-container{
    background: rgb(241,241,252);
    height: fit-content;
}
.jd-container{
    margin: 0px 15%;
}

/* job-detail header section starts  */
.job-detail-header {
    display: flex;
    flex-direction: column;
  }
.header-image-container {
    width: 100%;
    height: 136px;
    position: relative;
  }

.header-image-container img{
    width: 100%;
    height: 100%;
    border-radius: 15px 15px 0px 0px;
    object-fit: cover;
}
.contactNumber{
    display: flex;
    align-items: center;
    position: absolute;
    right: 15px;
    bottom: 15px;
    gap: 5px;
}
.contactNotext {
    color: white;
    margin-right: 18px;
    
}
.SocialMediaIcontag{
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 8px;
    height: 40px;
    width: 40px;
    padding-left: 10px;
    cursor: pointer;
}

.SocialMediaIcontag {
  position: relative;
}

.SocialMediaIcontag::before{
  content: attr(title);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #1F78C9;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.SocialMediaIcontag:hover::before{
  opacity: 1;
  visibility: visible;
}
.jd-header-body-container {
    background: white;
    border: 2px solid #dfe1e6;
    padding: 0 20px 13px 20px;
    border-radius: 0px 0px 12px 12px !important;
  }
.jd-specializationImage{
    width: 88px;
    height: 80px;
    background: #1F78C9;
    border: 5px solid #FFFFFF;
    border-radius: 14px;
    padding: 8px;
    position: absolute;
    top: 209px;
  }
.JobSpecializationLogo {
    width: 100%;
    height: 100%;
    fill: #ffff;
    display: inline-block;
}
.jd-header-body-right-content{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    position: relative;
    top: -50px;
    gap: 10px;
}
.jd-header-body-right-content span{
    background-color: #fff;
    color: #1F78C9;
    height: 40px;
    width: 40px;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 50%;
    cursor: pointer;
}
.jd-header-body-right-content span:hover{
  background-color: #1F78C9;
  color: #fff;
}
/* tool-tip css starts*/
.jd-share-talent-icon,
.jd-call-icon {
  position: relative;
  margin-right: 10px;
}

.jd-share-talent-icon::before,
.jd-call-icon::before {
  content: attr(title);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1F78C9;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  font-weight: bold;
  white-space: nowrap;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.jd-share-talent-icon:hover::before,
.jd-call-icon:hover::before {
  opacity: 1;
  visibility: visible;
}
/* tool-tip css  end*/
/* job-detail header section ends  */


/* job-detail description main content section starts  */
.jd-specialization-content{
    margin-top: 40px;
}
.jobSpecializationText{
    font-size: 27px;
}
.jobSpecializationPara{
    color: #666;
}
.job-content-container {
    display: flex;
    justify-content: space-between; 
  }


  .job-description-left-container {
    flex: 3; 
    margin-right: 2%; 
    margin-top: 2%;
    background-color: #fff;
    border-radius: 20px;
    padding: 3%;
    height: fit-content;
    border: 2px solid #dfe1e6;
  } 
  .jd-title{
    position: relative;
    right: 20px;
  }

  /* right side experience block starts */
  .job-description-right-container {
    /* flex: 1;  */
    background-color: #fff;
    border-radius: 20px;
    padding: 3%;
    margin-top: 2%;
    height: fit-content;
    border: 2px solid #dfe1e6;
    width: 35%;

 }
 .experience-block,
 .job-type,
 .jd-location{
  display: flex;
  align-items: flex-start;
 }

 /* .jd-primary-skills-section{
  display: flex;
  flex-wrap: wrap;
 } */
 /* .experience-block p,
.job-type p,
.jd-location p {
  flex: 1; 
  margin-right: 10px; 
} */
.minexp,
 .location-title,
 .job-type-title,
 .jd-secondary-skill,
 .jd-primary-skill{
  font-weight: bold;
 }
.job-type-detail,
.jd-location-detail,
.exp-duration-min,
.exp-duration{
  color: #999;
}
.exp-duration-min{
  margin-left: 14%;
  white-space: nowrap;
}
.exp-duration{
  margin-left: 12%;
  white-space: nowrap;
}
.job-type-detail{
  margin-left: 40%;
}
.jd-location-detail{
  /* margin-left: 75px; */
  margin-left: 40%;
  word-wrap: break-word;
  width: 35%;
}
.skill-tag{
    background: #1F78C9;
    border-radius: 19px;
    width: fit-content;
    color: white;
    padding: 4px 10px 4px 10px;
    font-size: 14px;
    margin-top: 8px;
    margin-right: 6px;
    text-transform: capitalize;
    display: inline-block;
    display: inline-block;
    /* white-space: nowrap;  */
    overflow: hidden;
    text-overflow: ellipsis; 

}
   /* right side experience block ends */
/* job-detail description main content section end  */

 /* refer-and-earn section starts */
 .jd-refer-and-earn{
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 2%;
    height:max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3%;
    margin-bottom: 2%;
    border: 2px solid #dfe1e6;
 }
 .refer-earn-title{
    display: flex;
    align-items: center;
    justify-content: center;
 }

 .refer-earn-title h1{
    font-size: 30px;
 }

 .refer-earn-title span{
    font-size: 14px;
    border: 2px solid #1F78C9;
    background: #1F78C9;
    border-radius: 50px;
    color: #fff;
    transition: 0.5s;
    padding: 2px 8px 2px 8px;
    height: min-content;
    margin-left: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    display: inline-block;
    display: inline-block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
 }
 .jd-create-link {
    display: flex;
    align-items: center; 
    justify-content: center;
    margin: 3%;
  }
  
  .jd-create-link input {
    border: 2px solid #1F78C9;
    color: #666;
    font-size: 18px;
    padding: 12.4px 15px 10.5px 19px;
    height: 60px;
    border-radius: 20px 0px 0px 20px;
  }
.create-link-btn{
    border: 2px solid #1F78C9;
    background: #1F78C9;
    border-radius: 15px;
    color: white;
    height: 60px;
    transition: 0.5s;
    border-radius: 0px 8px 8px 0px;
    cursor: pointer;
    padding: 12.4px 15px 10.5px 19px;
}

.create-link-btn:hover,
.refer-earn-title span:hover{
  background-color: #fff;
  color: #1F78C9;
}
 /* refer-and-earn section ends */


@media screen and (max-width: 920px) {
    .contactNumber{
        right: 20px;
        top: 0;
    }
    .jd-header-body-right-content{
        flex-direction: column;
        left: 87%;
        width: 50px;
    }
    .job-content-container {
        flex-direction: column;
    }

    .job-description-left-container{
      width: 100%;
      padding-left: 30px;
    }
    .job-description-right-container{
      width: 100%;
    }
    /* .job-type-detail,
    .jd-location-detail{
      margin-left: 0px !important;
    } */
    .exp-duration{
      left: 10%;
      position: relative;
    }
    .exp-duration-min{
      position: relative;
      left: 9%;
    }
    .job-type-title {
      position: relative;
      left: 24px;
  }
  .job-type-detail{
    position: relative;
    left: 5%;
  }
}

@media screen and (max-width: 480px) {
  .jd-container{
    margin: 0 4%;
  }
  .contactNumber{
    top: -47px;
    right: 5px;
  }
  /* experience-block  media query starts */
  .job-description-right-container{
    padding-left: 5%;
  }
  .minexp,
  .location-title,
  .job-type-title{
    font-size: 14px;
    width: 103px;
  }
  .exp-duration-min,
  .exp-duration,
  .job-type-detail,
  .jd-location-detail{
    font-size: 13px;
  }

  .exp-duration{
    left: 10%;
  }
  .exp-duration-min{
    left: 8%;
  }
.job-type-detail{
  left: -4%;
}
.jd-location-detail{
  margin-left: 26%;
}
  /* experience-block  media query ends */


  
    /* refer and earn media starts */
    .refer-earn-title{
      margin: 4%;
    }
    .refer-earn-title h1{
      font-size: 20px;
      white-space: nowrap;
    }
    .refer-earn-title span{
      font-size: 10px;
    }
    .jd-create-link {
      flex-direction: column; 
      align-items: center; 
    }
  
    .jd-create-link input {
      width: 100%; 
      border-radius: 20px;
    }
  
    .jd-create-link button {
      margin-left: 10px; 
      margin-top: 20px;
      border-radius: 20px;
    } 
    /* refer and earn media starts */

  }

  @media screen and (max-width: 350px) {
    .contactNotext{
      display: none;
    }
  }

  @media (min-width: 1301px) {
    .exp-duration{
      margin-left: 17%;
    }
    .exp-duration-min{
      margin-left: 19%;
    }
  }
  
  
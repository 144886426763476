.bolg-details-main-section{
    display: flex;
    background-color: #fff;
}
.blog-detials-left-section{
    width: 100%;}

.post-details-item-container{
  margin: 5%;}

.post-detail-item{
    margin-bottom: 20px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: rgb(241,241,252);
    padding: 3%;
    border-radius: 20px;}

.blog-detail-right-sidebar{
    margin: 2.5%;}

.blog-detail-posted-by-container{
    display: flex;}

.blog-detail-posted-by-container .post-date{
    margin-left: 5%;
    margin-top: 1.5%;
    color: #666;
    font-weight: bold;}
.post-details-item-container h3{
    margin: 5% 0;
    font-size: 28px;}
.blog-description {
    color: #666;
}


@media screen and (max-width: 920px) {
    .bolg-details-main-section{
        flex-direction: column;
    }
    .blog-detials-left-section{
        width: 100%;
    }
    .blog-detail-right-sidebar{
        margin-right: 10%;
    }
}
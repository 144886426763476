
/* single card style css in whyUs section */
.card{
  max-width: 350px; 
  padding: 20px;
  margin: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: transform 0.3s;
  height: fit-content;
  backdrop-filter: blur(0px); background-color: rgba(255, 255, 255, 1); border-radius: 38px; box-shadow: -35px 35px 68px 0px rgba(213, 221, 230, 0.5), inset 0px -0px 16px 0px rgba(213, 221, 230, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255);
}
.card:hover {
    transform: scale(1); 
    border: 1px solid #6610f2;

}
.card-image img{
    border-radius: 50%;
    height: 80px;
    width: 80px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    transition: transform 0.3s;
}

.card-content{
    margin-top: 50px;
    align-items: center;
}

.card:hover .card-image img {
    transform: translateY(-10px); 
}

@media (min-width: 992px){
.card {
    margin-top: 200px;
    height: 400px;
}
}


@media (min-width: 769px) and (max-width: 1175px) {
    .card{
        margin-top: 27%;
        height: 408px;
        width: 300px;
    }
}
div.btn-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

div.btn-container i {
    display: inline-block;
    position: relative;
    top: -9px;
}

label {
    font-size: 13px;
    color: #070707;
    font-weight: 500;
}

.btn-color-mode-switch {
    display: inline-block;
    margin: 0px;
    position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
    margin: 0px;
    width: 189px;
    height: 30px;
    background: #E0E0E0;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    display: block;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
    content: attr(data-on);
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    top: 7px;
    right: 20px;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    width: 89px;
    height: 17px;
    background: #9f9fc9;
    border-radius: 26px;
    position: absolute;
    left: 2px;
    top: 2px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 5px 0px;
}

.btn-color-mode-switch > .alert {
    display: none;
    background: #FF9800;
    border: none;
    color: #fff;
}

.btn-color-mode-switch input[type="checkbox"] {
    cursor: pointer;
    width: 80px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after {
    content: attr(data-on);
    left: 100px;
    background: #9f9fc9;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before {
    content: attr(data-off);
    right: auto;
    left: 20px;
}

.nav__ul li {
  position: relative;
}

.nav__ul li a {
  color: #999;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.3s; 
}

.nav__ul li:hover a {
  color: #ffff; 
  position: relative;
  overflow: hidden;
}

.nav__ul li:hover a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0; /* Start with no width */
  height: 2px; /* Adjust the height as needed */
  background-color: #ffff; 
  transition: width 0.3s ease-out; /* Add a smooth width transition */
}

#line-above-copyright{
  width: 94%;
  margin: auto;
}

.nav__ul li:hover a:hover::before,
.nav__ul li:hover a:hover::after {
  width: 50%; 
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: #ffff;
  background-color: #292930;
  padding-top: 56px;
}

#x-img{
  max-width: 71%;
  height: 72%;
  margin-top: 28px;
}

.footer__addr {
  margin-right: 1.25em;
  margin-bottom: 2em;
  flex: 1;
}

.footer__logo {
  font-family: 'Pacifico', cursive;
  font-weight: 400;
  text-transform: lowercase;
  font-size: 1.5rem;
}

.footer__addr h2 {
  margin-top: 1.3em;
  font-size: 15px;
  font-weight: 400;
}

.nav__title {
  font-weight: 400;
  font-size: 20px;
}

.footer address {
  font-style: normal;
  color: #999;
}

.footer__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: rgba(33, 33, 33, 0.07);
  border-radius: 100px;
  color: #2f2f2f;
  line-height: 0;
  margin: 0.6em 0;
  font-size: 1rem;
  padding: 0 1.3em;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav>* {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul--extra {
  column-count: 2;
  column-gap: 1.25em;
}

.legal {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #999;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.legal p {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.legal__links {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-left: auto;
}

form .inptfld {
  font-family: Open Sans;
  outline: none;
  border: none;
  font-size: 15px;
  padding: 10px;
  border-radius: 3px;
  width: 250px;
  background: #EDEEFC;
}

form .subscribebtn {
  font-family: open sans;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 3px;
  color: #fff;
  background: #34495e;
  cursor: pointer;
}

form .subscribebtn:hover {
  background: #233140;
  transition: all 0.6s ease;
}

.footer__top {
  display: flex;
  flex-direction: row;
}

.footer__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.footer__subscribe {
  flex: 2 1;
  margin-right: 1.25em;
  margin-bottom: 2em;
  margin-left: 2rem;
}

.footer__nav {
  flex: 3;
  display: flex;
  flex-direction: row;
}

.footer__bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.backedby ul {
  display: flex;
}

.backedby h2 {
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .footer__row {
    flex-direction: column;
  }
  #logo {
    height: 15rem;
    width: 15rem;
    margin: auto;
  }
  .footer__nav {
    flex-direction: row;
    margin-left: 20px;
  }
  .nav__item {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .backedby {
    text-align: center;
  }
  .backedby img {
    max-width: 18% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  #line-above-copyright {
    margin-top: 10px;
  }
}

@media screen and (min-width: 769px) {
  .backedby-logos {
    display: flex;
    flex-direction: row;
  }
}

.backedby {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backedby h2 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 1em;
}

.backedby ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 1em;
}

.backedby li {
  width: 60px;
}

.backedby img {
  max-width: 100%;
  min-height: 102%;
}

.contactus {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#phoneicon {
  transform: rotate(98deg);
}

#logo {
  height: 10rem;
  width: 15rem;
}

.contus h2 {
  margin-left: 11px;
}

.contactus ul {
  display: flex;
  flex-direction: row;
}

.icons {
  font-size: large;
  border: 2px solid rgb(255, 253, 253);
  border-radius: 50px;
  padding: 7px;
  height: 45px;
  width: 45px;
  color: #ffff;
  cursor: pointer;
}

.icons:hover {
  background-color: #b6baee;
  color: rgb(2, 3, 4);
}

.contactus ul li {
  display: flex;
  gap: 17px;
}





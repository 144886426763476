.getstartedimage  {
    height: 100%;
    width: 100%;
}
.getstartedimage img{
    width: 100%;
    height: 95%;
    object-fit: contain;
    margin-left: 8%;
    margin-top: 2%;

}

.getstartedContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #5355df;
    color: white;
    height: 50vh;
    overflow: hidden;

}

.leftText h4 {
    margin-bottom: 5px;
}

.leftText h1 {
    font-weight: 700;
    font-size: 50px;
    font-family: Manrope, sans-serif;
}

.leftText a {
    background: #ffc961;
    width: max-content;
    font-weight: 600;
    color: black;
    height: 58px;
    display: inline-flex;
    align-items: center;
    margin-top: 47px;

}

.leftText a:hover {
    color: white;
    background: black
}

.leftText {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    margin: 3%;
}

@media(max-width:945px) {
    .getstartedContainer {
        flex-direction: column-reverse;
        height: auto;
    }
    .getstartedimage{
        width: 70%;
        height: auto;
        margin: 20px;
        margin-top: 0;
    }
    .getstartedimage img {
        width: 100%;
    }

    .leftText {
        text-align: inherit;
    }
}


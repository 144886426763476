.share_talent_section{
    padding-top: 10px;
    background: rgb(241,241,252);
    min-height: 100vh;

    
}
.share_talent_section h1{
    text-align: center;
    top: 130px;
    position: relative;
}

.card-section{
    margin: 0 30px;

}

@media (max-width: 768px) {
    .share_talent_section h1 {
        top: 0;
        font-size: 24px; 
        margin-top: 10px;
        margin-bottom: 10px; 
    }

    .card-section {
        flex-direction: column; 
        align-items: center;
    }
}

@media (max-width: 900px) and (min-width: 790px) {
    .share_talent_section h1{
        margin-bottom: 50px;
    }
}
@media (min-width: 770px) and (max-width: 990px) {
   .card-section{
    margin-top: 150px;
   }

}
.about {
  min-height: 92vh;
  background-color: rgb(241, 241, 252, 0.3);
}

.about__content {
  min-height: calc(100vh - 106px);
  flex-direction: column-reverse;
}

.about__title {
  font-size: 4.1rem;
  line-height: 1.2;
  color: #222;
}

.about__content .para__text {
  padding: 3.6rem 0;
  max-width: 600px;
  margin: 0 auto;
  color: #222;
  font-size: 20px;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}


#outer-orbit {
  position: relative;
  z-index: -1;
  top: 0;
  left: 0;
  width: 190px;
  height: 180px;
  border: 2px #171718 dashed;
  border-radius: 100%;
  -webkit-animation: spin-right 20s linear infinite;
  animation: spin-right 20s linear infinite;
}

@keyframes spin-right {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.inner-circle {
  position: absolute;
  width: 120px;
  height: 130px;
  background-color: rgb(238, 189, 96);
  border-radius: 50%;
  transform: translate(29%, 16%);
}

.orbit-circle {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
  transform-origin: 50% 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: translate(72%, -27%) rotate(-17deg);
}

.orbit-circle {
  color: #171718;

}

.orbit-circle:hover {
  box-shadow: 0 45px 65px rgba(0, 0, 0, 0.50), 0 35px 22px rgba(0, 0, 0, 0.16);
}
.center-text {
  text-align: center;
}
.orbit-circle:nth-child(2) {
  transform: translate(-53%, 39%) rotate(-17deg) !important;
}
.orbit-circle:nth-child(3) {
  transform: translate(23%, 181%) rotate(-75deg);
}
.about__content--left {
  margin-top: 60px;
  margin-bottom: 25%;
}
@media (min-width: 992px) {
  .about__content {
    flex-direction: row;
  }

  .about__content--right {
    text-align: left;
    width: 60%;
  }

  .about__content--left {
    width: 40%;
    margin-right: 30%;
    margin-bottom: 0;
  }

  .about__content--right .para__text {
    margin-left: 0;
  }

  #outer-orbit {
    width: 376px;
    height: 382px;
  }

  .orbit-circle {
    width: 200px;
    height: 200px;

  }

  .inner-circle {
    width: 270px;
    height: 274px;
    transform: translate(18%, 20%);

  }

  .orbit-circle:nth-child(2) {
    transform: translate(-53%, 39%) rotate(-17deg) !important;
  }

  .orbit-circle:nth-child(3) {
    transform: translate(23%, 146%) rotate(-75deg);
  }
}

@media (max-width:375px) and (min-width:200px) {
  .about__content {
    margin-top: 2rem;
  }
}
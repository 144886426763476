.termscard
{
    height: 18rem;
  
    display: flex;
   
    border-radius: 12px;
    background: white;
    border: 1px solid hsla(0,3%,6%,.1);
    width: 26rem;
}
.termscard ul li{
    list-style-type:disc;
   
    font-size: 16px;
}
.termscard ul li:hover{
    color: #5355df;
    cursor: pointer;
}
.termscard ul{
    padding: 15px 35px;
}
.Notfoundcontainer{
    /* background: rgb(166, 164, 227); */
    background: rgb(91, 91, 242);
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }


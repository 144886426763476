.client-section {
    position: relative;
    flex-wrap: wrap;
    height: 150px;
    width: -webkit-fill-available;
    margin: 0 30px;
    bottom: -150px;
    border-radius: 20px;
    z-index: 22;
    background-color: #fff;
    /* background-color: #FFFAEB; */
    /* background-image: url('https://img.freepik.com/premium-photo/ribbon-bow-pink-color-symbol-breast-cancer-awareness-woman-female-girl-support-disease-background-copy-space-hope-health-care-survivor-charity-sign-medical-treatment-world-global-international-sick_178037-978.jpg'); */
    gap: 23px;
    max-width: 100%;
    padding: 10px;
    box-shadow: 
      rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, 
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, 
      rgba(255, 255, 255, 0.05) 0px 20px 0px 1px inset;
  }
  
  .client-image {
    width: 200px;
  }
  
  .client-image img {
    height: 100px;
  }
  
  @media (min-width: 1008px) {
    .client-section {
     bottom:0rem;
    }
  }
  
  @media (max-width: 1400px) {
    .client-section {
      left: 0;
      right: 0;
      border-radius: 20px;
      height: auto;
    }
  }
  
  @media (max-width: 768px) {
    .client-section {
      position: relative;
      top: 30px;
      padding: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .client-section {
      flex-direction: column;
      text-align: center;
    }
  
    .client-image {
      max-width: 100%;
      height: auto;
    }
  }
  
  
  
  @media (min-width: 777px) and (max-width: 1008px) {
    .client-section{
      top: 0;
    }
  }
  
  @media (min-width: 1007px) and (max-width: 1155px) {
    .client-section{
      position: relative;
      top: 0;
    }
  }
  
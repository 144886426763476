.case-card-study-contianer{
  /* background-image: linear-gradient(147deg, #FFE53B 0%, #fd3838 74%); */
  min-height: 100vh;
  font-family: 'Fira Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.case-study-heading{
  margin: 20px 0;
  text-align: center;
}

.case-study-card-item {
  width: 80%;
  background: #fff;
  background-color: rgb(231,231,251);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  border-radius: 25px;
  height: 300px;
  transition: all 0.3s;
  margin: 0 150px 20px 180px;
}
.case-card-study-contianer .case-study-card-item {
  display: flex;
  align-items: center;
}

.case-card-study-contianer .case-study__img {
  width: 250px;
  flex-shrink: 0;
  height: 250px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 20px;
  transform: translateX(-80px);
  overflow: hidden;
  position: relative;
}
.case-card-study-contianer .case-study__img img {
  width: 85%;
  height: 100%;
  object-fit: contain;
  margin-left: 8px;
}

.case-card-study-contianer .case-study__content {
  padding-right: 25px;
}

.case-card-study-contianer .case-study__text {
  color: #4e4a67;
  margin-bottom: 30px;
  line-height: 1.5em;
}



@media screen and (min-width:768px) and (max-width: 992px) {
  .case-study-card-item{
    margin-left: 100px;
    max-width: 680px;
  }
  .case-card-study-contianer{
  margin-top: -12%;
  }

}

@media screen and (max-width: 768px) {
  .case-study-card-item{
    min-height: 500px;
    height: auto;
    flex-direction: column;
    margin: 50px 0px 80px 50px;
  }

  .case-study__img{
    object-fit: cover;
    display: block;
    margin-left: 60%;
    position: relative;
    top: -55px;

  }

  .case-study__content{
    text-align: center;
  }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
  .case-card-study-contianer{
    margin-top: 0px;
    margin-left: 5%;
  }
  .case-study__img{
    margin-left: 27%;
  }
}